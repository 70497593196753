<script setup>
import { WdsPillSelect, WdsSheet } from '@wds/components';

defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['closeMenu', 'openLanguagePicker', 'openCurrencyPicker']);

// ## COMPOSABLES
const { isBelowMediumScreen } = useMq();

// ## STORES
const languageStore = useLanguageStore();
const currencyStore = useCurrencyStore();
</script>

<template>
  <div class="nav-button hamburger-menu">
    <div
      v-if="!toValue(isBelowMediumScreen) && isOpen"
      class="button-popover hamburger-popover"
    >
      <div class="hamburger-menu-body">
        <CommonHwNavBarSections />
      </div>
    </div>
    <WdsSheet
      :is-open="toValue(isBelowMediumScreen) && isOpen"
      @sheet-close="emit('closeMenu')"
    >
      <template #sheetHeader>
        <div class="hamburger-menu-header">
          <WdsPillSelect
            icon-start="globe"
            :text="languageStore?.getCurrentLanguage?.name"
            @click="emit('openLanguagePicker')"
          />
          <WdsPillSelect
            icon-start="money"
            :text="currencyStore?.getCurrentCurrency?.code"
            @click="emit('openCurrencyPicker')"
          />
        </div>
      </template>
      <template #sheetBody>
        <div class="hamburger-menu-body">
          <CommonHwNavBarSections />
        </div>
      </template>
    </WdsSheet>
  </div>
</template>

<style lang="scss" scoped>
.nav-button {
  position: relative;
  display: inline-flex;

  .button-popover {
    position: absolute;
    top: -12px;
    right: 0;
    margin-right: wds-rem(40px);
    background-color: $wds-color-white;
    z-index: $wds-z-index-dropdown;
    border-radius: $wds-border-radius-xl;
    box-shadow: $wds-shadow-light-l;
    min-width: wds-rem(300px);
  }

  &.hamburger-menu {
    position: absolute;
    right: 0;
    top: 0;

    .hamburger-menu-header {
      display: flex;
      gap: $wds-spacing-s;
    }

    .hamburger-menu-body {
      padding: 0;
    }

    > .hamburger-popover {
      .hamburger-menu-header {
        padding: $wds-spacing-s $wds-spacing-m;
        box-shadow: inset 0 -0.0625rem 0 0 #dddfe4;
      }

      .hamburger-menu-body {
        padding: $wds-spacing-s;
      }
    }
  }
}
</style>
